const BASE_URL = '/create-account/individual';

export const individualRoutesConfig = {
  steps: {
    currentUrl: [`${BASE_URL}/steps`],
    nextUrl: [`${BASE_URL}/personal`],
  },

  personalDetails: {
    currentUrl: '',
    nextUrl: [`${BASE_URL}/identification`],
  },

  identification: {
    currentUrl: '',
    nextUrl: [`${BASE_URL}/kin`],
  },

  nextOfKin: {
    currentUrl: '',
    nextUrl: [`${BASE_URL}/bank`],
  },

  bankDetails: {
    currentUrl: '',
    nextUrl: [`${BASE_URL}/documents`],
  },
  documentation: {
    currentUrl: '',
    nextUrl: '',
  },
};
